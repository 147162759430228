@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap");

html {
  /* zoom: 1.25; */
}
html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
body {
  /* font-family: 'DM Sans', sans-serif; */
  /* overflow: hidden; */
  /* background: #f8f8f9 !important; */
  /* overflow: hidden; */
  overflow-y: auto;
}

.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}

body.ko {
  font-family: "Noto Sans KR", sans-serif;
  word-break: keep-all;
}
.chakra-ui-light {
  background: #f8f8f9 !important;
}
option {
  color: black;
}

.custom-message {
  min-width: 50% !important;
  min-height: 74vh !important;
  box-shadow: none !important;
  background: transparent !important;
}

.navbar-custom {
  /* background-color: #000 !important; */
}
.message-wrapper {
  box-shadow: none !important;
  background: transparent !important;
}

.forn-home-wrapper {
  display: flex;
  align-items: center;
  height: 100vh;
  max-width: 820px;
  padding: 0 20px;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin: auto;
  /* position: relative; */
}
.forn-home-wrapper form {
  width: 100%;
}
.forn-home-wrapper-two {
  position: relative;
  width: 100%;
}
.forn-home-wrapper img {
  margin-bottom: 20px;
  position: absolute;
  bottom: 100%;
  /* top: calc() -100%; */
  width: 45%;
  left: 50%;
  transform: translateX(-50%);
}

.loading-dot {
  display: flex;
  justify-content: flex-start;
}

.dot {
  background-color: #edf0f0;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin: 0 5px;
  width: 10px;
  animation: loading-animation 1s ease-in-out infinite;
}

.chakra-text p {
  margin-bottom: 15px;
}
.chakra-text ol {
  padding-left: 50px;
  text-align: left;
}
.chakra-text ol li {
  margin-bottom: 3px;
}

.chakra-text ul li {
  margin-bottom: 3px;
}

.user-message {
  font-weight: 700;
  font-family: "Noto Sans", sans-serif;
}

.general-msg p {
  margin-bottom: 1px;
  line-height: 2;
  font-family: "Noto Sans", sans-serif;
}
@keyframes loading-animation {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.loading-area {
  width: 100%;
  align-items: center;
  height: calc(100vh - 114px);
  padding-left: 32px;
  padding-top: 20px;
}

.loading-area > div {
  /* margin: auto;
  margin-left: 50%;
  margin-top: 200px; */
}

.chakra-ui-light {
  /* background: linear-gradient(135deg, #868CFF 0%, #4318FF 100%) !important; */
}

form.form-overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.sidebar-scroll {
  width: 100% !important;
  overflow: auto !important;
}

.ad-container {
  width: 100%;
  height: 300px;
}

.fc-scroller {
  padding-bottom: 150px;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  /* padding-top: 15px;  */
  overflow-x: hidden !important;
}

.memory-item:hover .memory-buttons {
  opacity: 1;
}

.memory-buttons {
  opacity: 0;
  transition: 0.4s;
  margin-left: auto;
}

.google-tab button {
  position: relative;
}
.google-tab button:focus {
  outline: none;
  box-shadow: none;
}
button.tab-btn:after {
  position: absolute;
  content: "";
  width: 1px;
  height: 12px;
  background: #000;
  top: 7px;
  right: 0;
}

button.tab-btn:last-of-type:after {
  display: none;
}

.updated-memory-item > :first-child {
  /* Remove the padding */
  padding-top: 0;
  padding-bottom: 0;
}

.after-border {
  position: relative;
}

.after-border:after {
  position: absolute;
  content: "";
  height: 15px;
  width: 1px;
  background-color: #000;
  left: calc(100% + 15px);
  top: 5px;
}
.react-player video,
.react-player iframe,
.react-player__preview {
  border-radius: 10px !important;
}

.history-list-container::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}

/* Show the scrollbar on hover */
.history-list-container:hover::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
  background: transparent; /* Adjust the background color as needed */
}

/* Style the scrollbar thumb */
.history-list-container:hover::-webkit-scrollbar-thumb {
  background: #898989;
}
.history-list-container::-webkit-scrollbar-thumb {
  background: transparent; /* Adjust the background color as needed */
  border-radius: 3px; /* Adjust the border radius as needed */
}

input.memory-input {
  line-height: 1;
  color: rgba(255, 255, 255, 0.8);
  /* font-size: 16px; */
  height: 16px;
  /* memory-input */
}

.google-search-item span {
  font-weight: 400;
  margin-right: 5px;
  color: #1c73e2;
  font-family: "Noto Sans", sans-serif;
  /* color: rgb(43, 108, 176); */
}

.icon-16 {
  height: 16px !important;
  width: 16px !important;
}

.header-ads {
  height: 60px;
  width: 100%;
}

.animated-icon {
  animation: spin-fade 1.5s infinite linear;
}

.menu-item-btn span {
  display: flex;
}
/* .animated-icon:hover {
 
} */
@keyframes spin {
  0% {
    transform: scale(0);
    transform: translateX(-10px);
  }
  100% {
    transform: scale(1);
    transform: translateX(10px);
  }
}

.first-modal .swiper-pagination {
  top: calc(100% - 43px) !important;
}

.first-modal .swiper {
  /* padding-bottom: 60px; */
}
.first-modal button.chakra-modal__close-btn {
  right: 8px;
}
.first-modal button.chakra-modal__close-btn:hover {
  background: transparent;
}
.first-modal button.chakra-modal__close-btn:active,
.first-modal button.chakra-modal__close-btn:focus {
  background: transparent;
  outline: 0;
  border: 0;
  box-shadow: none;
}
@keyframes spin-fade {
  0% {
    opacity: 0;
    transform: scale(0.8) translateX(0px);
  }
  50% {
    opacity: 1;
    transform: scale(1) translateX(0px);
  }
  100% {
    opacity: 0;
    transform: scale(0.8) translateX(0px);
  }
}

.counter {
  display: flex;
  align-items: flex-end;
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  background-color: #000;
  padding: 1rem;
  border-radius: 0.5rem;
}

.digit {
  position: relative;
  top: 0;
}

.digit.animated {
  animation: count 0.5s ease-in-out forwards;
}

.text {
  margin-left: 0.5rem;
}
.search-input {
  animation: fadeIn 1s ease-in-out;
}

.chat-height {
  padding: 20px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 20px;
  margin: 15px;
  /* margin-top: 10px; */
  padding: 20px;
  height: calc(100% - 4px) !important;
  width: calc(100% - 20px) !important;
  margin-bottom: 0;
  margin-top: 0;
  /* border-top: 15px solid transparent; */
}
/* p="20px" 
                boxShadow={''}
                bg="#ffffff"
                borderRadius={'4px'} */

.general-msg {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  color: #495057;
  font-size: 16px;
  display: inline-block;
}

.user-message.general-msg {
  /* font-weight: 700; */
}

.video-playback {
  filter: brightness(0.4);
}

.search-root * {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  color: #495057;
  font-size: 16px !important;
  margin-top: 0 !important;
}
.search-root {
  margin-top: 0 !important;
  line-height: 2;
}
.loading-area.update-area {
  padding: 0px !important;
}
.loading-area.message-loading {
  padding-right: 30px !important;
}
.ReactAwesomeAnimatedNumber.edit-count {
  /* margin-top: 12px; */
}
.ReactAwesomeAnimatedNumber.edit-count * {
  font-weight: 500 !important;
  color: #5f6368 !important;
  font-family: Nunito Sans, sans-serif !important;
  display: block !important;
  line-height: 1 !important;
  font-size: 17px !important;
  /* margin-top: 20px; */
}

.bold-text {
  color: #000;
}

.feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.animation-time {
  background-color: #ea4335;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.animated-bg {
  background-color: #dadce0;
  position: absolute;
  width: 140px;
  height: 140px;
  top: -20px;
  left: -20px;
  z-index: -1;
  border-radius: 100%;
  animation: scaleAnimation 1s linear infinite;
}

.grid.details-active > div {
  height: calc(100vh - 250px) !important;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-icon {
  animation: spin 2s linear infinite;
}

.cursor-pointer {
  cursor: pointer;
}

.prompt-modal-details {
  width: 59%;
  padding: 25px 25px 90px 25px;
}

.prompt-modal-details .slick-dots {
  top: 100%;
}

.prompt-modal-details-right {
  width: calc(41% + 2px);
  height: 100%;
  position: absolute;
  left: 59%;
  top: 0;
}

.scrollbar-bg-Box::-webkit-scrollbar {
  width: 0.5em; /* Adjust scrollbar width */
}

.scrollbar-bg-Box::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-bg-Box::-webkit-scrollbar-thumb {
  background: #ddd; /* Set the thumb (foreground) to transparent */
  border-radius: 10px;
}
/* Hide scrollbar for Firefox */
.scrollbar-bg-Box {
  scrollbar-width: thin; /* Adjust scrollbar width */
  /* scrollbar-color: transparent transparent; */
}

.singlePlan ul {
  padding-left: 20px;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes count {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* .disabled-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; 
} */

.disabled-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3); /* Adjust opacity as needed */
  z-index: 1;
  backdrop-filter: blur(5px); /* Adjust blur amount as needed */
}
.message-overlay {
  position: absolute;
  font-weight: 700;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
}

.disabled-plan.singlePlan {
  /* background: #ddd; */
}
.description h5 {
  font-size: 25px;
  margin-bottom: 15px;
}
.bg_1 {
  background-image: url("../img/bg.jpg");
}
.bg_2 {
  background-image: url("../img/bg2.jpg");
}
.bg_3 {
  background-image: url("../img/dg1.jpg");
}
.un-img {
  /* object-fit: cover;
  object-position: center; */
  border-radius: 10px;
  margin-bottom: 10px;
  width: auto;
  height: 100%;
}
.img-error {
  display: none;
}
.h-100 {
  min-height: 100vh;
  height: auto;
}
.pl-0 {
  padding-left: 0 !important;
}

.chakra-p-m-b-0 p {
  margin-bottom: 0 !important;
}
.chakra-modal__content-container {
  align-items: self-start !important;
  padding-top: 30px;
  padding-bottom: 30px;
}
.chakra-text ul {
  margin-left: 50px;
}

pre {
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px;
  margin: 10px 0;
  background-color: rgba(0, 0, 0, 1);
  border: 1px solid #ccc;
  /* color: #ccc; */
  border-radius: 5px;
  white-space: pre-wrap;
}

.underline {
  text-decoration: underline;
}

.modal-review-preview {
  position: fixed;
  background: transparent;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.gpt-message.general-msg table {
  border-collapse: collapse;
  width: 100%;
}

.gpt-message.general-msg th,
.gpt-message.general-msg td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.gpt-message.general-msg th {
  background-color: #f2f2f2;
}

@keyframes typing {
  from {
    width: 1px;
  }
  to {
    width: 2px;
  }
}

.typing-indicator {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 5px;
  height: 20px;
  animation: typing 0.5s alternate infinite;
  background-color: #495057;
  transform: translateY(-5px);
}

.general-msg h1,
.general-msg h2,
.general-msg h3,
.general-msg h4,
.general-msg h5,
.general-msg h6 {
  font-weight: 700;
  margin-top: 10px;
  /* border-bottom: 1px solid hsla(0,0%,50%,.33); */
}
.general-msg p,
.general-msg li,
.general-msg ul,
.general-msg ol {
  color: #24292f;
  /* color: #1B2559; */
  font-size: 16px;
  margin-inline-end: 6px;
  line-height: 24px;
  font-family: -apple-system, "Segoe UI", Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji";

  /* font-family: "Noto Sans", sans-serif; */
  font-weight: 400;
}
.general-msg h1 {
  font-size: 30px;
}

.general-msg h2 {
  font-size: 26px;
}

.general-msg h3 {
  font-size: 24px;
}

.general-msg h4 {
  font-size: 22px;
}

.general-msg h5 {
  font-size: 20px;
}
.general-msg h6 {
  font-size: 18px;
}

/* .typing-indicator span {
  display: inline-block;
  background-color: #000; 
  width: 6px;
  height: 6px;
  border-radius: 50%;
  animation: typing 0.5s alternate infinite;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
} */
.promp__top-right {
  display: flex;
  align-items: center;
}
.scroll-apps > div:first-child {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  margin-right: 0 !important;
  white-space: nowrap; /* this ensures content doesn't wrap to next line, forcing horizontal scroll */
}
.response-markdown.wmde-markdown-var.w-md-editor.w-md-editor-show-live {
  /* min-height: 100px !important; */
  background-color: transparent;
  box-shadow: none;
  height: auto !important;
}
.response-markdown .w-md-editor-toolbar,
.response-markdown .w-md-editor-bar,
.response-markdown .w-md-editor-input {
  display: none;
}

.response-markdown .w-md-editor-preview {
  border: none;
  width: 100%;
  padding: 0;
  box-shadow: none;
  background-color: transparent;
}

.response-markdown .wmde-markdown {
  background-color: transparent;
  /* color: black !important; */
}

.response-markdown .w-md-editor-preview {
  position: inherit;
}

.MENU_LIST {
  transform: translateX(-142px) !important;
}
.MENU_LIST.MENU_LIST_CHINGU {
  transform: translateX(0) !important;
}

.PROFILE_DROPDOWN {
  /* transform: translateX(15px) !important; */
}

.PROFILE_DROPDOWN_TWO {
  transform: translateX(76px) !important;
}
.PROFILE_DROPDOWN_TWO_CHINGU.PROFILE_DROPDOWN_TWO {
  transform: translateX(0px) !important;
}
.search-input-message::placeholder {
  color: #495057; /* Same as your textarea font color */
  opacity: 1; /* Necessary to ensure consistent color in all browsers */
}
.search-input-message {
  /* Existing styles for .search-input-message, if any */

  /* Styles to hide scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Styles for Firefox */
  scrollbar-width: none;

  /* Styles for Internet Explorer and Edge */
  -ms-overflow-style: none;
}

.spinner {
  animation: spin 1s linear infinite;
}

.text-mb-0 {
  margin-bottom: 0 !important;
}
.cycle-bottom {
  /* margin-bottom: 4px; */
}

.loading-dots::after {
  content: "";
  animation: loadingDots 1.5s steps(1, end) infinite;
}

span.loading-dots {
  position: absolute;
}

@keyframes loadingDots {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: ".";
  }
}

.chat-active {
  position: relative;
}
.chat-active::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #53ba95;
  border-radius: 100%;
  top: 7px;
  right: -15px;
}

svg.ace-icon {
  width: 20px;
}

.make-center-searchbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.make-center-searchbar .searchbar-wrapper {
  padding-bottom: 121px !important;
  width: 100%;
}
.make-center-searchbar.make-center-searchbar-shortcut .searchbar-wrapper {
  padding-top: 220px !important;
  /* width: 100%; */
}
section#chakra-modal-deposit\ chakra-modal__content-container {
  margin: auto;
}

.list-align-dev ul {
  padding-left: 20px !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.CHECKOUT_CHECKBOX span {
  border-color: #000;
  border-width: 1px;
}

.html-text-wrapper ol,
.html-text-wrapper ul{
  padding-left: 30px;
}
@media screen and (max-width: 991px) {
  .chakra-text ul {
    margin-left: 20px !important;
  }
  .chakra-modal__content-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .prompt-modal-details {
    width: 100%;
    padding: 25px 25px 115px 25px;
  }
  .prompt-modal-details-right {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  #w___video {
    object-fit: cover;
    object-position: center;
  }
  /* .sm-center-height-fix{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0px 20px;
  } */
  /* .main-content-sm{
    height: calc(100vh - 200px)!important;
  }
  .footer-bottom-sm-fixed{
    bottom: 0px;
    height: 107px;
  }
  .header-top-sm-fixed{
    top: 0;
    height: 58px;
  }
  .footer-bottom-sm-fixed,
  .header-top-sm-fixed{
    position: fixed;
    width: 100%;
    background: #620707!important;
    z-index: 9;
    left: 0;
  } */
}

/* 

.shadow-scrollbar-track {
  position: relative;
  background-color: transparent;
}

.shadow-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.4);
}

.shadow-scrollbar-view {
  position: relative;
  overflow: hidden;
} */

.message-block-extra {
  font-weight: 400;
  color: #484444;
}
.react-joyride__beacon {
  display: none !important;
}

.question-type {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-radius: 0 !important;
}

.pagi-active {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pagi-active .single-thumb {
  justify-content: center;
}

.pagi-active .single-thumb > .left {
  background-color: transparent;
}

.switch-wrapper {
  position: relative;
  display: inline-flex;
  padding: 4px;
  border: 1px solid lightgrey;
  margin-bottom: 40px;
  border-radius: 30px;
  background: white;
}

.switch-wrapper label {
  font-size: 16px;
  z-index: 1;
  min-width: 100px;
  line-height: 32px;
  cursor: pointer;
  border-radius: 30px;
  transition: color 0.25s ease-in-out;
  width: 50%;
  text-align: center;
}

.switch-wrapper label:hover {
  background: whitesmoke;
}
.switch-wrapper label.activated {
  background-image: linear-gradient(
    90deg,
    rgb(0, 205, 250) -20.67%,
    rgb(144, 88, 255) 42.73%,
    rgb(255, 118, 223) 105.77%
  );
  /* background: rgb(0, 121, 107); */
  color: #fff;
}

.react-international-phone-input-container {
  margin-bottom: 20px !important;
}
.react-international-phone-input {
  width: calc(100% - 50px);
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  border: 1px solid #e0e5f2 !important;
  height: 3rem !important;
  font-weight: 500 !important;
  color: #1b254b !important;
}
.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  border-radius: 16px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  height: 3rem !important;
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  padding-left: 10px !important;
}
